import React, { FC } from 'react';
import styled from 'styled-components';
import { LayoutBase } from './LayoutBase';
import {
    mobileBreakPoint,
    Header,
    Footer,
    LayoutType
} from '../components';

const MainPost = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    padding: var(--space-l);
    margin-top: calc(var(--space-xl) * 2);

    @media ${mobileBreakPoint} {
        padding: var(--space-s);
    }
`;

export const LayoutPost: FC<any> = ({ children }) => (
    <LayoutBase>
        <Header layoutType={LayoutType.POST} hideNavBar={false} />
        <MainPost>
            {children}
            <Footer></Footer>
        </MainPost>
    </LayoutBase>
);
