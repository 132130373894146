import React, { FC, Component } from 'react'
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
// import Zooming from 'zooming/src/index';
import Zooming from '../vendors/zooming.min.js';
import Fade from 'react-reveal/Fade';

import { SEO, PostPagination, queryPostToPost, mobileBreakPoint, PostInfos, PostImage } from '../components';
import { LayoutPost } from '../layouts/LayoutPost';
import isMobile from '../utils/isMobile';
import getEventOptions from '../utils/getEventOptions';

const DesktopGridWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
`;

const DesktopImageWrapper = styled.div`
    height: auto;
        margin: var(--space-xxs);
`;

const CaptionText = styled.div`
    color: var(--color);

    div {
        &:first-child {
            font-size: 1.2rem;
            line-height: 1.4;
            font-weight: 600;
        }

        &:not(:first-child) {
            font-size: 0.8rem;
            line-height: 1.2;
        }
    }
`;

export const DesktopGrid: FC<any> = ({ imagesList, onImageLoad }) => {
    return (
        <DesktopGridWrapper>
            {imagesList.map((item: PostImage, i: number) => (
                <DesktopImageWrapper key={i + 1}>
                    <Fade bottom distance="100px">
                        <Img fixed={item.src.childImageSharp.desktop}
                            onLoad={onImageLoad}
                            draggable={false}
                            style={{
                                overflow: 'unset',
                                width: ((200 * item.src.childImageSharp.desktop.width) / item.src.childImageSharp.desktop.height) + 'px',
                                maxHeight: '200px',
                            }}
                            imgStyle={{
                                width: 'auto',
                                height: '200px',
                            }}
                        />
                    </Fade>

                    <CaptionText>
                        <div>{item.title}</div>
                        <div>{item.description}</div>
                        <div>{item.dimension}</div>
                    </CaptionText>
                </DesktopImageWrapper>
            ))}
        </DesktopGridWrapper>
    );
};



const MobileGridWrapper = styled.div<any>`
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
`;

const MobileImageWrapper = styled.div`
    height: auto;
    width: 100%;
    margin-bottom: var(--space-l);

    @media ${mobileBreakPoint} {
        margin-bottom: var(--space-s);
    }
`;

export const MobileGrid: FC<any> = (props) => {
    const { imagesList, onImageLoad } = props;

    return (
        <MobileGridWrapper>
            {imagesList.map((item: any, i) => {
                return (
                    <MobileImageWrapper key={i + 1}>
                        <Fade bottom distance="100px">
                            <Img fluid={item.src.childImageSharp.mobile}
                                onLoad={onImageLoad}
                                draggable={false}
                                style={{
                                    overflow: 'unset',
                                    width: '100%',
                                    maxWidth: '100%',
                                }}
                                imgStyle={{
                                    height: 'auto',
                                }}
                            />
                        </Fade>

                        <CaptionText>
                            <div>{item.title}</div>
                            <div>{item.description}</div>
                            <div>{item.dimension}</div>
                        </CaptionText>
                    </MobileImageWrapper>
                );
            })}
        </MobileGridWrapper>
    );
}


interface Props {
    pageContext: any;
    data: any;
}

interface State {
    layoutType: LayoutType;
}

export enum LayoutType {
    MOBILE = 'MOBILE',
    TABLET = 'TABLET',
    DESKTOP = 'DESKTOP'
}

export default class Post extends Component<Props, State> {
    zooming: any;

    constructor(props: any) {
        super(props);

        this.state = {
            layoutType: LayoutType.DESKTOP
        };

        this.onImageLoad = this.onImageLoad.bind(this);
        this.updateLayoutType = this.updateLayoutType.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateLayoutType, getEventOptions());
        this.updateLayoutType();

        this.initZooming();
        this.updateZooming();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateLayoutType, false);
    }

    componentDidUpdate() {
        this.updateZooming();
    }

    updateLayoutType() {
        const { layoutType } = this.state;

        let newLayoutType: LayoutType = LayoutType.DESKTOP;

        if (typeof window !== 'undefined') {
            if (window.innerWidth <= 490) {
                newLayoutType = LayoutType.MOBILE;
            } else if (window.innerWidth <= 770) {
                newLayoutType = LayoutType.TABLET;
            } else {
                newLayoutType = LayoutType.DESKTOP;
            }
        }

        if (newLayoutType !== layoutType) {
            this.setState({ layoutType: newLayoutType });
        }
    }

    initZooming() {
        this.zooming = new Zooming({
            bgColor: 'rgba(0, 0, 0, 0.3)',
            scaleBase: 0.9,
            scaleExtra: 0.7,
            enableGrab: !isMobile(),
            scrollThreshold: !isMobile() ? 40 : 80,
            onBeforeOpen: (target: any) => {
                target.parentNode.parentNode.parentNode.style.removeProperty('animation-name');
            },
            // onClose: (target: any) => {}
        });
    }

    updateZooming() {
        if (this.zooming) {
            setTimeout(() => {
                this.zooming.listen('.gatsby-image-wrapper > picture > img');
            }, 500);
        }
    }

    onImageLoad() {
        this.updateZooming()
    }

    render() {
        const { layoutType } = this.state;
        const { pageContext, data } = this.props;

        const post = queryPostToPost(data);
        return (
            <LayoutPost>
                <SEO postPath={pageContext.slug} postNode={post} />
                <PostInfos post={post} onlyTitle={false} />
                {layoutType === LayoutType.DESKTOP
                    ? <DesktopGrid imagesList={post.images} onImageLoad={this.onImageLoad} />
                    : <MobileGrid imagesList={post.images} onImageLoad={this.onImageLoad} />
                }
                <PostPagination next={pageContext.next} prev={pageContext.prev} />
            </LayoutPost>
        )
    }
}

export const postQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        node: markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 280)
            html
            frontmatter {
                title
                description
                date(formatString: "DD.MM.YYYY", locale: "fr-FR")
                displayDate
                category
                tags
                images {
                    title
                    description
                    dimension
                    date(formatString: "DD.MM.YYYY", locale: "fr-FR")
                    src {
                        extension
                        childImageSharp {
                            mobile: fluid(maxWidth: 490, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp,
                                presentationWidth
                                presentationHeight
                            }

                            desktop: fixed(height: 800, quality: 100) {
                                ...GatsbyImageSharpFixed_withWebp,
                                width
                                height
                            }
                        }
                    }
                }
            }
        }
    }
`;

/*
tablet: fluid(maxWidth: 770, quality: 100) {
    ...GatsbyImageSharpFluid_withWebp,
    presentationWidth
    presentationHeight
}

desktop: fluid(maxHeight: 800, quality: 100) {
    ...GatsbyImageSharpFluid_withWebp,
    presentationWidth
    presentationHeight
}
*/
